import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { ExternalLink, PropertyGroup } from '../../components';

import css from './ListingPage.module.css';

const SectionFeaturesMaybe = props => {
  const { options, publicData, extendedDataKey } = props;

  if (!publicData) {
    return null;
  }
  // console.log('publicData', publicData);
  const { pattern } = publicData;

  return pattern ? (
    <div className={css.sectionFeatures}>
      <h2 className={css.featuresTitle}>
        <FormattedMessage id="ListingPage.patternTitle" />
      </h2>

      {/* <ExternalLink
        href={pattern?.startsWith('http') ? pattern : `https://${pattern}`}
        target="_blank"
      > */}
      {pattern}
      {/* </ExternalLink> */}
    </div>
  ) : null;
};

export default SectionFeaturesMaybe;
