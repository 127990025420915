import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';

import css from './ListingPage.module.css';
import config from '../../config';
import _ from 'lodash';

const SectionDetailsMaybe = props => {
  const { publicData, customConfig } = props;
  const { listing, filters } = customConfig || {};

  if (!publicData || !customConfig || !listing?.enumFieldDetails) {
    return null;
  }
  const { size, color, category, sub_category, gender, pattern } = publicData;
  const categoryOptions = [
    ...config.custom.womenCategoriesOptions,
    ...config.custom.menCategoriesOptions,
    ...config.custom.kidsCategoriesOptions,
  ];
  const subCategoryOptions = [
    ...config.custom.menTopSubcategories,
    ...config.custom.menBottomsSubcategories,
    ...config.custom.menCoatsAndJacketsSubcategories,
    ...config.custom.menFootwearSubcategories,
    ...config.custom.menAccessoriesSubcategories,
    ...config.custom.menNightwearSubcategories,
    ...config.custom.menUnderwearSubcategories,
    ...config.custom.menSwimwearSubcategories,
    ...config.custom.menBagsSubcategories,
    ...config.custom.womenTopSubCategories,
    ...config.custom.womenBottomSubCategories,
    ...config.custom.womenDressSubCategories,
    ...config.custom.womenCoatsAndJacketSubCategories,
    ...config.custom.womenFootWearsSubCategories,
    ...config.custom.womenAccessoriesSubCategories,
    ...config.custom.womenNightwareSubCategories,
    ...config.custom.womenUnderwearSubCategories,
    ...config.custom.womenSwimwearSubCategories,
    ...config.custom.womenBagsSubCategories,
    ...config.custom.babyGirlSubcategories,
    ...config.custom.babyBoySubcategories,
    ...config.custom.toddlerGirlSubcategories,
    ...config.custom.toddlerBoySubcategories,
    ...config.custom.girlYouthSubcategories,
    ...config.custom.boyYouthSubcategories,
    ...config.custom.girlTeenSubcategories,
    ...config.custom.boyTeenSubcategories,
  ];
  const sizeConfigOptions = [
    ...config.custom.genericSize,
    ...config.custom.footwearMenSize,
    ...config.custom.footwearWomenSize,
    ...config.custom.size0_24m,
    ...config.custom.toddler2_6,
    ...config.custom.size4_16
  ];
  const sizeOptions = size?.map(item => sizeConfigOptions.find(sizeItem => sizeItem.key === item));

  const sectionSize =
    size?.length > 0 ? (
      <div className={css.sectionDetails}>
        <h2 className={css.detailsTitle}>
          <FormattedMessage id="ListingPage.sectionSize" />
        </h2>
        <div className={css.container}>
          {_.sortBy(sizeOptions, 'id')?.map((item, index) => (
            <div key={index} className={css.size}>
              {item.label}
            </div>
          ))}
        </div>
      </div>
    ) : null;
  const colorOptions = color?.map(item =>
    config.custom.colorOptions.find(colorItem => colorItem.key === item)
  );
  const sectionColor =
    size?.length > 0 ? (
      <div className={css.sectionDetails}>
        <h2 className={css.detailsTitle}>
          <FormattedMessage id="ListingPage.color" />
        </h2>
        <div className={css.container}>
          {_.sortBy(colorOptions, 'id')?.map((item, index) => (
            <div className={css.color} key={index}>
              <p
                className={css.colorIcon}
                style={
                  item?.color !== 'multi'
                    ? {
                        backgroundColor: `${item.color}`,
                        border: `1px solid ${item.border ?? null}`,
                      }
                    : {
                        backgroundImage:
                          'linear-gradient(to right, red,orange,yellow,green,blue,indigo,violet)',
                      }
                }
              ></p>
              <p>{item.label}</p>
            </div>
          ))}
        </div>
      </div>
    ) : null;
  return (
    <>
      <div className={css.sectionDetails}>
        <div style={{ marginBottom: '2rem' }}>
          <h2 className={css.detailsTitle}>
            <FormattedMessage id="ListingPage.detailsTitle" />
          </h2>
          <ul className={css.details}>
            {gender ? (
              <li className={css.detailsRow}>
                <span className={css.detailLabel}>
                  <FormattedMessage id="ListingPage.genderTitle" />
                </span>
                <span>{config.custom.genderOptions.find(item => item.key === gender)?.label}</span>
              </li>
            ) : null}
            {category ? (
              <li className={css.detailsRow}>
                <span className={css.detailLabel}>
                  <FormattedMessage id="ListingPage.categoryTitle" />
                </span>
                <span>{categoryOptions?.find(item => item.key === category)?.label}</span>
              </li>
            ) : null}
            {sub_category ? (
              <li className={css.detailsRow}>
                <span className={css.detailLabel}>
                  <FormattedMessage id="ListingPage.subCategoryTitle" />
                </span>
                <span>{subCategoryOptions?.find(item => item.key === sub_category)?.label}</span>
              </li>
            ) : null}
          </ul>
        </div>
      </div>
      {sectionColor}
      {sectionSize}
    </>
  );
};

export default SectionDetailsMaybe;
